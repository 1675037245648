<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-snackbar
      :timeout="3000"
      :value="true"
      center
      centered
      color="red"
      v-model="showsnackbar"
    >
      <v-layout align-center pr-4 wrap>
        <v-flex xs3>
          <v-img
            height="45px"
            contain
            :src="require('./../../assets/images/close.png')"
          ></v-img>
        </v-flex>
        <v-flex xs9>
          <span style="font-size: 20px"> {{ msg }}</span>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 lg11 pa-0 pa-sm-6>
        <v-card
          class="mx-auto elevation-2 pa-6"
          max-width="1088px"
          align="center"
          outlined
        >
          <!----------------------filters---------------------------->
          <v-layout wrap justify-end pb-2>
            <v-flex xs12 sm4 text-left pb-2>
              <span class="nsbold" style="font-size: 20px">
                Edit Attendance
              </span>
            </v-flex>

            <v-flex xs12 sm3 md2 lg2 pr-1 v-if="appUser.usertype == 1">
              <v-select
                :items="HostelList"
                v-model="hostel"
                label="Hostel"
                class="rounded-xl"
                item-text="hostelName"
                item-value="_id"
                clearable
                hide-details
                @input="getHostelWarden()"
                outlined
                dense
              ></v-select>
            </v-flex>
            <v-flex xs12 sm3 md2 lg2 pr-1 v-if="appUser.usertype == 1">
              <v-select
                :items="hostelwardenList"
                v-model="hostelwarden"
                label="Warden"
                class="rounded-xl"
                item-text="name"
                item-value="_id"
                clearable
                hide-details
                @input="getBlockid()"
                outlined
                dense
              ></v-select>
            </v-flex>
            <v-flex xs12 sm3 md2 lg2 pr-1>
              <v-select
                :items="BlockIds"
                v-model="hostelblockid"
                label="Select Block"
                class="rounded-xl"
                item-text="Block"
                item-value="_id"
                hide-details
                @change="getData()"
                outlined
                dense
              ></v-select>
            </v-flex>
            <v-flex xs12 sm3 md2 lg2 pr-1>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="Date"
                    outlined
                    readonly
                    dense
                    hide-details
                    class="rounded-xl"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  no-title
                  scrollable
                  @change="$refs.menu.save(fromDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="count != null">
            <v-flex xs12>
              <v-tabs
                v-if="count"
                v-model="firstTab"
                color="#766BC0"
                grow
                class="nsbold"
                style="font-size: 16px"
              >
                <v-tab v-if="count"
                  >Absent({{ count.numberOfStudentsAbsent }})
                </v-tab>
                <v-tab v-if="count">
                  Present({{ count.numberOfStudentsPresent }})</v-tab
                >
                <!--------------------absent---------------------------->
                <v-tab-item>
                  <v-card>
                    <v-layout wrap v-if="absentdata">
                      <v-flex xs12 v-if="absentdata.length > 0">
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">No.</th>
                                <th class="text-left">Ad.No</th>
                                <th class="text-left">Name</th>
                                <th class="text-left">Attendance</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(value, i) in absentdata" :key="i">
                                <!-- <td>{{ i + 1 }}</td> -->
                                <td>
                                  <span v-if="page == 1">
                                    {{ i + 1 }}
                                  </span>
                                  <span v-else>
                                    {{ i + 1 + 20 * (page - 1) }}
                                  </span>
                                </td>
                                <td>{{ value.admissionNo }}</td>
                                <td>{{ value.name }}</td>
                                <td>
                                  <v-btn
                                    small
                                    color="green"
                                    outlined
                                    @click="
                                      (markpresentDialogue = true),
                                        (absent = value)
                                    "
                                    >Mark Present</v-btn
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-flex>
                      <v-flex text-center v-else> No data found </v-flex>
                    </v-layout>
                  </v-card>
                </v-tab-item>
                <!--------------------present---------------------------->
                <v-tab-item>
                  <v-card>
                    <v-layout wrap v-if="presentdata">
                      <v-flex xs12 v-if="presentdata.length > 0">
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">No.</th>
                                <th class="text-left">Ad.No</th>
                                <th class="text-left">Name</th>
                                <th class="text-left">Attendance</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(value2, i) in presentdata" :key="i">
                                <!-- <td>{{ i + 1 }}</td> -->
                                <td>
                                  <span v-if="page == 1">
                                    {{ i + 1 }}
                                  </span>
                                  <span v-else>
                                    {{ i + 1 + 20 * (page - 1) }}
                                  </span>
                                </td>
                                <td>{{ value2.admissionNo }}</td>
                                <td>{{ value2.name }}</td>
                                <td>
                                  <v-btn
                                    small
                                    color="green"
                                    outlined
                                    @click="
                                      (markabsentDialogue = true),
                                        (present = value2)
                                    "
                                    >Mark Absent</v-btn
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-flex>
                      <v-flex text-center v-else> No data found </v-flex>
                    </v-layout>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-flex>
          </v-layout>
          <v-layout wrap v-else>
            <span>Attendance not marked</span>
            </v-layout>

        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog width="400px" v-model="markpresentDialogue">
      <v-card width="400px">
        <v-toolbar dark color="grey" dense flat class="body-2">
          <v-toolbar-title> Mark Present </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4 black--text"
          >Are you sure you want to mark this student as present?
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="dialoge = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#766BC0"
            class="body-2 font-weight-bold"
            outlined
            @click="markPresent()"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="400px" v-model="markabsentDialogue">
      <v-card width="400px">
        <v-toolbar dark color="grey" dense flat class="body-2">
          <v-toolbar-title> Mark Absent </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4 black--text"
          >Are you sure you want to mark this student as absent?
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="dialoge = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#766BC0"
            class="body-2 font-weight-bold"
            outlined
            @click="markAbsent()"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "../../store";
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
      Pagelength: 0,
      pageinner: 1,
      Pagelengthinner: 0,
      markabsentDialogue: false,
      markpresentDialogue: false,
      absent: [],
      present: [],
      menu: false,
      menu2: false,
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      defaulttodate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      keyword: null,
      msg: null,
      //    issueStatus : ["All","Approved", "Out", "Active", "In"],
      cvalue: "All",
      dataid: null,
      ServerError: false,
      appLoading: false,
      attendance: [],
      HostelList: [],
      BlockIds: [],
      hostelblockid: null,
      hostel: null,
      downloadBtn: false,
      hostelwardenList: [],
      hostelwarden: null,
      individualViewPresent: [],
      individualViewAbsent: [],
      usertype1: false,
      viewDialoge: false,
      sessionId: null,
      count: null,
      presentdata: [],
      absentdata: [],
      myData: [],
      loader: null,
      loading: false,
      showsnackbar: false,
      excel: {
        adno: "",
        name: "",
        class: "",
        hostelid: "",
        warden: "",
        present: "",
        absent: "",
      },
      json_fields: {
        Ad_No: "adno",
        Name: "name",
        Class: "class",
        Hostel: "hostelid",
        Warden: "warden",
        Present_Days: "present",
        Absent_Days: "absent",
      },
    };
  },
  computed: {
    appUser() {
      return store.state.userData;
    },
  },
  mounted() {
    this.gethostel();
    this.getBlockid();
    if (this.appUser.usertype == 0) {
      this.usertype1 = true;
      this.getBlockid();
    }
    if (this.fromDate) {
      var dt = new Date();
      dt.setDate(dt.getDate());
      this.fromDate = dt.toISOString().substr(0, 10);
    }
    if (this.from) {
      var dlt = new Date();
      dlt.setDate(dlt.getDate() - 30 + 1);
      this.fromDate = dlt.toISOString().substr(0, 10);
    }
    this.downloadBtn = false;
    this.getData();
  },
  watch: {
    // hostelblockid(){
    //   this.downloadBtn=false;
    //   this.usertype1=false;
    //   console.log("id=",this.hostelblockid)
    //    this.getData();
    // },
    fromDate() {
      this.downloadBtn = false;
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/section/getalllist/web",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          blockId: this.hostelblockid,
          date: this.fromDate,
          warden: this.hostelwarden,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // this.attendance = response.data.data;

            this.count = response.data.data;
            this.sessionId = response.data.data._id;
            this.viewData();
            // this.Pagelength = Math.ceil(response.data.totalLength / 50);
          }
          else{
            this.count = null;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    getBlockid() {
      (this.hostelblockid = null), (this.appLoading = true);
      axios({
        method: "post",
        url: "/warden/hostelblock/getlist",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          wardenid: this.hostelwarden,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            if (response.data.hosteldata) {
              if (response.data.hosteldata.length > 0) {
                this.BlockIds = response.data.hosteldata;
                if (this.usertype1 == true) {
                  this.hostelblockid = response.data.hosteldata[0]._id;
                  this.getData();
                }
                //  this.hostelblockid = response.data.hosteldata[0]._id;
                //  this.getData();
              }
            }
            // this.Pagelength = Math.ceil(response.data.totalLength / 50);
          }
          //  else {
          //   this.appTimes();
          // }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    viewData() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/student/present/absent/getlist/web",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.sessionId,
          blockId: this.hostelblockid,
          warden: this.hostelwarden,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.absentdata = response.data.absentList;
            this.presentdata = response.data.presentList;
            this.dataid = response.data.attandanceDataId;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    gethostel() {
      axios({
        method: "get",
        // url: "/hostel/name/getlist",
        url: "main/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.HostelList = response.data.data;
            //  this.getData();
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getHostelWarden() {
      (this.hostelwarden = null),
        (this.hostelblockid = null),
        // console.log("edititem=",this.hostelblockcurItem)
        (this.appLoading = true);
      axios({
        method: "get",
        url: "hostel/warden/getlist/hostelwise",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          mainHostelId: this.hostel,
          // page: this.page,
          // limit: 50,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.hostelwardenList = response.data.data;
            // this.getData();
            // this.Pagelength = Math.ceil(response.data.totalLength / 50);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    markAbsent() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/attandance/mark/absent",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          studentId: this.present._id,
          id: this.dataid,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.markabsentDialogue = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
            this.viewData();
          } else {
            this.markabsentDialogue = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    markPresent() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/attandance/mark/present",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          studentId: this.absent._id,
          id: this.dataid,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.markpresentDialogue = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
            this.viewData();
          } else {
            this.markpresentDialogue = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
    formatDate2(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
<style scoped>
.buttonload {
  background-color: #04aa6d; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 24px; /* Some padding */
  font-size: 16px; /* Set a font-size */
}

/* Add a right margin to each icon */
.fa {
  margin-left: -12px;
  margin-right: 8px;
}
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
